import React from 'react'
import transition from "../transition";
import {Cursor, Typewriter} from "react-simple-typewriter";
import MainTiltCard from './MainTiltCard';
import TopTiltCard from './TopTiltCard';
import BottomTiltCard from './BottomTiltCard';
import  MouseImageTrail  from './MouseImageTrail';
import pic1 from "./1.png";
import pic2 from "./2.png";
import pic3 from "./3.png";
import pic4 from "./4.png";
import pic5 from "./5.png";
import pic6 from "./6.png";
import pic7 from "./7.png";
import pic8 from "./8.jpg";
import pic9 from "./9.jpg";
import pic10 from "./10.jpg";
import pic11 from "./11.jpg";
import pic12 from "./12.png";
import pic13 from "./13.jpg";
import pic14 from "./14.png";
import pic15 from "./15.jpg";
import pic16 from "./16.jpg";
import pic17 from "./17.jpg";
import pic18 from "./18.jpg";
import pic19 from "./19.jpg";
import pic20 from "./20.jpg";
import pic21 from "./21.jpg";
import pic22 from "./22.jpg";
import pic23 from "./23.jpg";
import pic24 from "./24.jpg";

const Home = () => {

    return (
    <div>
      <h1>
        <Typewriter className = "typeWriter" words= {['.joyastick', '.js', 'aldric-joya.js']}/>
        <Cursor />
      </h1>
        
        <MouseImageTrail
      renderImageBuffer={150}
      rotationRange={25}
      images={[
        pic1,
        pic2,
        pic3,
        pic4,
        pic5,
        pic6,
        pic7,
        pic8,
        pic9,
        pic10,
        pic11,
        pic12,
        pic13,
        pic14,
        pic15,
        pic16,
        pic17,
        pic18,
        pic19,
        pic20,
        pic21,
        pic22,
        pic23,
        pic24,
      ]}
    >
        <div className ="flex flex-row gap-5 justify-center mt-20 mb-44 z-1">
          <MainTiltCard/>
          <div className ="flex flex-col gap-5 z-1">
            <TopTiltCard/>
            <BottomTiltCard/>
          </div>
        </div>
    </MouseImageTrail>
    </div>
  );
}

export default transition(Home);
