import React from 'react'
import transition from "../transition";
import {Typewriter, Cursor} from "react-simple-typewriter";


const About = () => {
  return (
    <div>
      <h1>
        <Typewriter className = "typeWriter" words= {['projects']} typeSpeed = {150} loop = {1}/><Cursor /><span>.js</span>
      </h1>
    </div>
  )
}

export default transition(About);