import React from 'react'
import transition from "../transition";
import {Typewriter, Cursor} from "react-simple-typewriter";
import ContactTiltCard from './ContactTiltCard';

const Contact = () => {
  return (
    <div>
      <h1>
        <Typewriter className = "typeWriter" words= {['contact']} typeSpeed = {150} loop = {1}/><Cursor /><span>.js</span>
      </h1>
      <div className ="flex flex-row gap-5 justify-center mt-20 mb-44 z-1">
          <ContactTiltCard/>
      </div>
    </div>
  )
}

export default transition(Contact);