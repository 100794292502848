import React from 'react'
import {Link} from "react-router-dom"
import './logo192.png';

const NavBar = () => {
  return (
    <div className= 'nav'>
      <div className='nav-links'>
          <div className="nav-item">
              <Link className = "nav-link" to="/">
                Home
              </Link>
          </div>
        <div className="nav-item">
            <Link className = "nav-link" to="/about">
            Projects
            </Link>
        </div>
        <div className="nav-item">
            <Link className = "nav-link" to="/contact">
            Contact
            </Link>
        </div>
      </div>
    </div>
  )
}

export default NavBar